import React from 'react';
import { useSnackbar, CustomContentProps, enqueueSnackbar } from 'notistack';
import { ButtonProps } from 'components/ui-components-v2/Button';

declare module 'notistack' {
    interface VariantOverrides {
        error: false;
        warning: false;
        info: false;
        success: false;
    }
}

export interface SnackbarOptions extends Partial<CustomContentProps> {
    severity?: 'success' | 'info' | 'warning' | 'error';
    primaryAction?: ButtonProps;
    secondaryAction?: ButtonProps;
}

// Define the type of the snackbar ref
type SnackbarRef = {
    enqueueSnackbar: typeof enqueueSnackbar;
};

// Declare a global snackbar ref
let useSnackbarRef: SnackbarRef;

// Define the setter function with the correct type
const setUseSnackbarRef = (useSnackbarRefProp: SnackbarRef) => {
    useSnackbarRef = useSnackbarRefProp;
};

// Define the props type for InnerSnackbarUtilsConfigurator
interface InnerSnackbarUtilsConfiguratorProps {
    setUseSnackbarRef: (ref: SnackbarRef) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<InnerSnackbarUtilsConfiguratorProps> = ({ setUseSnackbarRef }) => {
    setUseSnackbarRef(useSnackbar());
    return null;
};

export const SnackbarUtilsConfigurator = () => {
    return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

export default {
    success(message, options?: SnackbarOptions) {
        this.toast(message, { severity: 'success', ...options });
    },
    warning(message, options?: SnackbarOptions) {
        this.toast(message, { severity: 'warning', ...options });
    },
    info(message, options?: SnackbarOptions) {
        this.toast(message, { severity: 'info', ...options });
    },
    error(message: string, options?: SnackbarOptions) {
        this.toast(message, { severity: 'error', ...options });
    },
    toast(message: string, options?: SnackbarOptions) {
        useSnackbarRef.enqueueSnackbar(message, options);
    }
};
